/// <reference types="@types/google.maps" />

import {
  AddressType,
  GeocodeResult,
  Place,
} from '@googlemaps/google-maps-services-js';

import { LocationFragment } from '../Claim/LocationType';
import { RichLocation } from './Location';
import { stateToCode } from './states';

export function makeAddressTextFrom(
  loc: LocationFragment | undefined,
): string | undefined {
  if (loc?.addressText) {
    return loc.addressText;
  }

  if (loc?.line1 && loc.state && loc.city) {
    return makeAddressTextLinesFrom(loc).filter(Boolean).join(', ');
  }

  return undefined;
}

/**
 * This function takes a LocationFragment and returns an array of strings
 * representing the lines of the address in typical display format.
 */
export function makeAddressTextLinesFrom(loc: LocationFragment): string[] {
  const cityState = [loc.city, loc.state].filter(Boolean).join(', ');
  const cityStateZip = [cityState, loc.postalCode].filter(Boolean).join(' ');
  return [loc.line1 ?? '', loc.line2 ?? '', cityStateZip];
}

/**
 * This function normalizes a result from either the Google Geocode API or
 * Google Places Autocomplete API into a standard RichLocation object.
 */
export function googlePlaceToLocation({
  apartmentNumber,
  includeAddressComponents,
  includePartialMatch,
  initialLocationFragment,
  isApartment,
  place,
  preferGoogleFormattedAddress = true,
  isNotEnriched = false,
}: {
  apartmentNumber?: string;
  includeAddressComponents?: boolean;
  includePartialMatch?: boolean;
  initialLocationFragment?: LocationFragment;
  isApartment?: boolean;
  place: GeocodeResult | google.maps.places.PlaceResult | Place;
  preferGoogleFormattedAddress?: boolean;
  isNotEnriched?: boolean;
}): RichLocation & { partialMatch?: boolean } {
  const line1Components =
    place.address_components?.filter(
      c =>
        c.types.includes('street_number' as AddressType) ||
        c.types.includes(AddressType.route),
    ) ?? [];
  const line2Components =
    place.address_components?.filter(c =>
      c.types.includes(AddressType.subpremise),
    ) ?? [];
  const cityComponent =
    place.address_components?.find(c =>
      c.types.includes(AddressType.locality),
    ) ??
    place.address_components?.find(c =>
      c.types.includes(AddressType.sublocality),
    );

  const countyComponent = place.address_components?.find(c =>
    c.types.includes(AddressType.administrative_area_level_2),
  );
  const stateComponent = place.address_components?.find(c =>
    c.types.includes(AddressType.administrative_area_level_1),
  );
  const postalComponent = place.address_components?.find(
    c =>
      c.types.includes(AddressType.postal_code) &&
      (c.long_name !== '0' || c.short_name !== '0') &&
      !c.types.includes('postal_code_prefix'),
  );
  const countryComponent = place.address_components?.find(c =>
    c.types.includes(AddressType.country),
  );

  const stateCode =
    countryComponent?.short_name === 'US' &&
    stateComponent?.short_name &&
    stateComponent?.short_name.length > 2
      ? stateToCode(stateComponent?.short_name)
      : stateComponent?.short_name;

  const line2 =
    apartmentNumber ??
    line2Components
      .map(c => c.short_name)
      .join(' ')
      .trim();
  const placeResult = place as google.maps.places.PlaceResult;
  const result = {
    businessGooglePlaceId: place.place_id, // Google Place ID
    ...(placeResult?.name && placeResult.types?.includes('establishment')
      ? {
          businessName: placeResult.name,
        }
      : {
          businessName: null,
        }),
    line1: line1Components
      .map(c => c.short_name)
      .join(' ')
      .trim(),
    line2: isApartment ? `(Apartment #${line2})` : line2,
    apartmentNumber: line2 || null,
    city: cityComponent?.short_name || undefined,
    county: countyComponent?.short_name || undefined,
    state: stateCode || undefined,
    postalCode: postalComponent?.short_name || undefined,
    ...googlePlaceCoordinates(place as google.maps.places.PlaceResult),
    ...(initialLocationFragment
      ? {
          latitude: initialLocationFragment.latitude,
          longitude: initialLocationFragment.longitude,
        }
      : {}),
    ...(includeAddressComponents
      ? { addressComponents: place?.address_components ?? [] }
      : {}),
    country: countryComponent?.short_name,
    enrichedAt: isNotEnriched ? undefined : new Date(),
  } as Omit<RichLocation, 'addressText'> & { partialMatch?: boolean };

  if (includePartialMatch) {
    result.partialMatch = (place as GeocodeResult).partial_match;
  }

  return {
    ...result,
    addressText:
      preferGoogleFormattedAddress && place.formatted_address
        ? place.formatted_address
        : makeAddressTextFrom({
            ...result,
            line1:
              (place as google.maps.places.PlaceResult).name ?? result.line1,
          }) ?? '',
  };
}

function googlePlaceCoordinates(
  place: google.maps.places.PlaceResult | GeocodeResult,
): {
  latitude: number;
  longitude: number;
} {
  const { lat, lng } = place.geometry?.location || { lat: 0, lng: 0 };
  return {
    latitude: typeof lat === 'number' ? lat : lat(),
    longitude: typeof lng === 'number' ? lng : lng(),
  };
}
