import './DateTime.css';

import classNames from 'classnames';

import {
  StepComponentControlsErrorProps,
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';

import { useHashString, useMobileDetect } from '../../../hooks';
import Label from '../Label';
import { DesktopDateTime } from './DesktopDateTime';
import {
  calculateNewValue,
  getDateBoundaries,
  getTimezoneIfProvided,
  getValue,
  isUTCDateTime,
} from './helpers';
import { MobileDateTime } from './MobileDateTime';

import type {
  DateTimeV2StepComponentSpec,
  UTCDateTime,
} from '@assured/step-renderer/';
type DateTimeProps = StepComponentSharedProps<
  DateTimeV2StepComponentSpec,
  UTCDateTime | undefined
> &
  StepComponentControlsErrorProps;

export const DateTimeV2: StepComponentFC<DateTimeProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  error,
  showErrorMessages,
}) => {
  const { isMobile } = useMobileDetect();
  const {
    date_must_be_before,
    date_must_be_after,
    date_mode,
    wide_display: wideDisplay,
    mode,
    field,
    timezone,
    existing_value,
  } = step_component;
  if (primaryValue && !isUTCDateTime(primaryValue)) {
    const v = getValue({ primaryValue });
    updateValue(
      field,
      v ? { rawValue: v.toISO({ includeOffset: false }) } : undefined,
    );
  }

  const dateOnly = mode === 'date';
  const zone = getTimezoneIfProvided({ existing_value, timezone });
  const { dateMustBeBefore, dateMustBeAfter } = getDateBoundaries({
    date_mode,
    date_must_be_before,
    date_must_be_after,
  });
  const value = getValue({ primaryValue, existing_value });
  const hashedField = useHashString(field);
  const onChangeDate = (newDate?: Date) => {
    const newValue = calculateNewValue({
      newDate,
      zone,
      existing_value,
    });

    if (newValue) {
      updateValue(field, newValue);
    }
  };

  const renderDatePicker = (displayMobile: boolean) => {
    return displayMobile ? (
      <MobileDateTime
        dateOnly={dateOnly}
        value={value?.toISO({ includeOffset: false })}
        hashedField={hashedField}
        error={error}
        onChange={onChangeDate}
      />
    ) : (
      <DesktopDateTime
        dateMustBeAfter={dateMustBeAfter}
        dateMustBeBefore={dateMustBeBefore}
        wideDisplay={wideDisplay}
        dateOnly={dateOnly}
        value={value}
        hashedField={hashedField}
        onChange={onChangeDate}
      />
    );
  };

  return (
    <div className={classNames(className, 'mt-4')}>
      {typeof step_component?.label === 'string' ? (
        <Label step_component={step_component} htmlFor={hashedField} />
      ) : (
        <label className="sr-only" id="date-label" htmlFor={hashedField}>
          {step_component?.title || 'Date/time input'}
        </label>
      )}
      {renderDatePicker(isMobile)}
      {error && showErrorMessages ? (
        <div className="error-message" role="alert" aria-live="assertive">
          {error}
        </div>
      ) : null}
    </div>
  );
};

DateTimeV2.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};
