import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const PartyEntryListItem = ({
  icon,
  title,
  description,
  className,
}: {
  icon: ReactNode;
  title: string;
  description: string;
  className?: string;
}) => {
  return (
    <div
      className={twMerge('flex items-center justify-left px-3 py-2', className)}
    >
      {icon}
      <div
        className="flex flex-col justify-start pl-3"
        style={{ textAlign: 'left' }}
      >
        <span className="text-gray-600 text-sm">{title}</span>
        <span className="font-bold text-gray-600 text-sm">{description}</span>
      </div>
    </div>
  );
};
