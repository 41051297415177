import { DisplayMode, RegionTemplate } from './types';

export const upperLeg: { upper_leg: RegionTemplate } = {
  upper_leg: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'hip',
        label: 'Hip joint',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [DisplayMode.AUTO, DisplayMode.PROPERTY],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_hip',
        label: 'Dislocated hip',
        tabs: ['hip'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.WORKERS_COMP],
      },
      {
        key: 'hyperextension_hip',
        label: 'Hyperextended hip',
        tabs: ['hip'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['hip'],
        popular_in_modes: [DisplayMode.AUTO],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['hip'],
        popular_in_modes: [DisplayMode.AUTO],
      },

      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
      },
      {
        key: 'severed_upper_leg',
        label: 'Severed',
        tabs: ['bone'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'amputation',
        label: 'Amputated',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'crushed_upper_leg',
        label: 'Crushed',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
