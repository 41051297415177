import {
  YourInvolvementData,
  YourInvolvementType,
} from '@assured/shared-types/ClaimWorkflow/YourInvolvementTypes';
import { isVehicleInvolvementType } from '@assured/shared-types/ClaimWorkflow/YourInvolvementHelpers';
import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import YourInvolvementModificationStepComponentSpec from '@assured/step-renderer/types/step-components/YourInvolvementModification';
import { ChangeEvent, useEffect, useState } from 'react';
import Select, { Option, Options } from 'react-select';
import { SvgYourInvolvementBicyclist } from '@assured/design-system/src/components/Svg/SvgYourInvolvementBicyclist';
import { SvgYourInvolvementPedestrian } from '@assured/design-system/src/components/Svg/SvgYourInvolvementPedestrian';
import { SvgYourInvolvementWitness } from '@assured/design-system/src/components/Svg/SvgYourInvolvementWitness';
import { SvgYourInvolvementVehicleUnknownSeat } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUnknownSeat';
import { SvgYourInvolvementVehicleUninvolvedOwner } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUninvolvedOwner';
import { SvgYourInvolvementVehicleUninvolvedNonOwner } from '@assured/design-system/src/components/Svg/SvgYourInvolvementVehicleUninvolvedNonOwner';
import Seatmap from '../../Seatmap';
import { upperFirst } from 'lodash';
import { VehicleOccupantSeat } from '@assured/shared-types/Claim/Vehicle/InvolvedParty';

// Just use as a null fallback, but shouldn't be needed in real-world cases.
const unknownYourInvolvementData: YourInvolvementData = {
  identifier: 'unknown-id',
  involvementType: YourInvolvementType.UNKNOWN,
  isWitness: false,
  isInvolved: true,
};
const seatmapResponsiveClasses =
  'flex flex-col gap-6 items-center [&>*]:scale-[85%]';

type YourInvolvementModificationProps = StepComponentSharedProps<
  YourInvolvementModificationStepComponentSpec,
  {
    // This is a workaround to allow a JSON workflowState update to make
    // its way through our field parser/injector to the back end. The
    // update object must contain the field name as a key.
    'json:workflowState:yourInvolvement': {
      needsChanges: boolean;
      data: YourInvolvementData;
    };
  }
>;

function getVehicleLabel(yourInvolvementData: YourInvolvementData): string {
  if (!yourInvolvementData.vehicleData) {
    return 'Unknown Vehicle';
  }
  let label = '';
  if (yourInvolvementData.vehicleData.colorLabel) {
    label += `${yourInvolvementData.vehicleData.colorLabel} `;
  }
  label += yourInvolvementData.vehicleData.make || 'Unknown vehicle';
  if (yourInvolvementData.vehicleData.model) {
    label += ` ${yourInvolvementData.vehicleData.model}`;
  }
  return label;
}

function getIconAndLabelForInvolvementData(data: YourInvolvementData): {
  icon: string;
  label: string;
} {
  let label = '';
  let icon = '';
  switch (data.involvementType) {
    case YourInvolvementType.PEDESTRIAN:
      label = 'Pedestrian';
      icon = '🚶';
      break;
    case YourInvolvementType.BICYCLIST:
      label = 'Bicyclist';
      icon = '🚲';
      break;
    case YourInvolvementType.WITNESS:
      label = 'Witness';
      icon = '👀';
      break;
    // FIXME - need to handle the normal <div> color swatch for vehicles.
    // Our out-of-date version of react-select doesn't support this easily...
    // All of these cases provide the same icon/label for the vehicle.
    case YourInvolvementType.VEHICLE_UNSPECIFIED:
    case YourInvolvementType.UNINVOLVED_OWNER:
    case YourInvolvementType.UNINVOLVED_NON_OWNER:
    case YourInvolvementType.VEHICLE_UNKNOWN_SEAT:
    case YourInvolvementType.VEHICLE:
      label = getVehicleLabel(data);
      icon = '🚗';
      break;
    case YourInvolvementType.NOT_INVOLVED:
      label = 'Not Involved';
      icon = '❌';
      break;
    default:
      label = 'Unknown';
      icon = '❓';
      break;
  }
  return { label, icon };
}

function getSelectOptionForYourInvolvementData(
  data: YourInvolvementData,
): Option<string> {
  if (!data) {
    return {
      label: 'Unknown',
      value: 'unknown',
    };
  }
  const { icon, label } = getIconAndLabelForInvolvementData(data);
  return {
    label: `${icon} ${label}`,
    value: data.identifier,
  };
}

function getGraphicElementForInvolvementData(
  data: YourInvolvementData,
  seatmapClickHandler?: (seat: string) => void,
): JSX.Element | null {
  if (!data) {
    return null;
  }
  const className = 'w-full';
  const key = `your-involvement-graphic-${data.involvementType}`;
  if (data.involvementType === YourInvolvementType.PEDESTRIAN) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementPedestrian className={className} key={key} />
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.BICYCLIST) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementBicyclist className={className} key={key} />
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.WITNESS) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementWitness className={className} key={key} />
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.NOT_INVOLVED) {
    return (
      <div className="text-center" key={`${key}-container`}>
        <h2>Are you sure you were not involved?</h2>
        <p>
          If you are associated with a vehicle, but not seated in the vehicle at
          the time of the incident, please select a vehicle you are associated
          with.
        </p>
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.UNINVOLVED_OWNER) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementVehicleUninvolvedOwner
          className={className}
          key={key}
        />
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.UNINVOLVED_NON_OWNER) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementVehicleUninvolvedNonOwner
          className={className}
          key={key}
        />
      </div>
    );
  }
  if (data.involvementType === YourInvolvementType.VEHICLE_UNKNOWN_SEAT) {
    return (
      <div key={`${key}-container`}>
        <SvgYourInvolvementVehicleUnknownSeat className={className} key={key} />
      </div>
    );
  }
  if (isVehicleInvolvementType(data.involvementType)) {
    // vehicle cases should always have vehicleData and a vehicle layout
    if (!data.vehicleData) {
      return null;
    }
    if (!data.vehicleData.vehicle_layout) {
      return null;
    }
    let seatLabel = `${upperFirst(
      (data.vehicleData.seat || 'UNKNOWN').split('_').join(' ').toLowerCase(),
    )} seat`;
    if (seatLabel === 'Unknown seat') {
      seatLabel = 'Where were you seated?';
    }
    return (
      (data.vehicleData.isOccupant && (
        <>
          <h2
            key={`${key}-seatmap-seat-label`}
            className="text-gray-600 text-center text-lg"
          >
            {seatLabel}
          </h2>
          <div
            className={seatmapResponsiveClasses}
            key={`${key}-seatmap-container`}
          >
            <Seatmap
              vehicleLayout={data.vehicleData.vehicle_layout}
              allowAnySeat
              disabledSeats={[]}
              selected={[data.vehicleData.seat || 'UNKNOWN']}
              onClick={seatmapClickHandler}
            />
          </div>
        </>
      )) ||
      null
    );
  }
  // Unknown and Not Involved, VEHICLE_UNSPECIFIED, etc.
  // It's quite common to not return a graphic for several types
  return null;
}

const YourInvolvementModification: StepComponentFC<
  YourInvolvementModificationProps
> = ({ step_component, primaryValue, updateValue }) => {
  // Track which involvement ID is currently selected
  const [selectedInvolvementDataId, setSelectedInvolvementDataId] =
    useState<string>(step_component.yourInvolvementData.identifier);

  // This value will hold everything we know about each involvement option.
  // As the user selects an option and adds data, we will add to the data here,
  // and if the user selects a previously-selected option, we can fill out the
  // controls from what we've learned already about that selection.
  const [allInvolvementData, setAllInvolvementData] = useState<
    Record<string, YourInvolvementData>
  >({});

  // populate allInvolvementData from the potentialInvolvements passed up via the step component
  useEffect(() => {
    const builtAllDataMap: Record<string, YourInvolvementData> = {};
    step_component.potentialInvolvements.forEach(data => {
      builtAllDataMap[data.identifier] = data;
    });
    setAllInvolvementData(builtAllDataMap);
  }, [step_component.potentialInvolvements]);

  const yourInvolvementSelectOptions = step_component.potentialInvolvements.map(
    getSelectOptionForYourInvolvementData,
  );

  const handleSelectChange = (
    newValue: Option<string> | Options<string> | null,
  ) => {
    if (newValue === null) {
      return;
    }
    const optionValue = (newValue as Option<string>).value || 'unknown';
    setSelectedInvolvementDataId(optionValue);
  };

  const onInVehicleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isOccupant = e.currentTarget.value === 'true';
    const updatedData = structuredClone(currentlySelectedData);
    if (updatedData?.vehicleData) {
      updatedData.vehicleData.isOccupant = isOccupant;
    }
    if (isOccupant) {
      updatedData.involvementType = YourInvolvementType.VEHICLE_UNSPECIFIED;
    } else {
      // Update to reflect non-occupant status
      updatedData.involvementType = updatedData.vehicleData?.isOwner
        ? YourInvolvementType.UNINVOLVED_OWNER
        : YourInvolvementType.UNINVOLVED_NON_OWNER;
      if (updatedData.vehicleData?.seat) {
        updatedData.vehicleData.seat = undefined;
      }
    }
    setAllInvolvementData({
      ...allInvolvementData,
      [selectedInvolvementDataId]: updatedData,
    });
  };

  const onIsOwnerChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isOwner = e.currentTarget.value === 'true';
    const explicitNotOwner = e.currentTarget.value === 'false';
    const updatedData = structuredClone(currentlySelectedData);
    if (updatedData?.vehicleData) {
      updatedData.vehicleData.isOwner = isOwner;
    }
    // If we have known answers for both in vehicle and is owner, we can
    // determine the Non Involved types:
    if (currentlySelectedData.vehicleData?.isOccupant === false && isOwner) {
      updatedData.involvementType = YourInvolvementType.UNINVOLVED_OWNER;
    }
    if (
      currentlySelectedData.vehicleData?.isOccupant === false &&
      explicitNotOwner
    ) {
      updatedData.involvementType = YourInvolvementType.UNINVOLVED_NON_OWNER;
    }
    setAllInvolvementData({
      ...allInvolvementData,
      [selectedInvolvementDataId]: updatedData,
    });
  };

  const handleSeatmapClick = (seat: string) => {
    const updatedData = structuredClone(currentlySelectedData);
    if (updatedData?.vehicleData) {
      updatedData.vehicleData.seat =
        VehicleOccupantSeat[seat as keyof typeof VehicleOccupantSeat];
    }
    updatedData.involvementType = YourInvolvementType.VEHICLE;
    setAllInvolvementData({
      ...allInvolvementData,
      [selectedInvolvementDataId]: updatedData,
    });
  };

  const onConfirmClick = () => {
    const yourInvolvementData = allInvolvementData[selectedInvolvementDataId];
    // The "json:..." wrapper key is a workaround to allow a JSON workflowState
    // update to make its way through our field parser/injector to the back end.
    // The update object must contain the field name as a key, otherwise it is
    // filtered out before processing.
    const updateObject = {
      'json:workflowState:yourInvolvement': {
        needsChanges: true,
        data: yourInvolvementData,
      },
    };
    updateValue(step_component.field, updateObject);
  };

  // convenience value for the currently selected data
  const currentlySelectedData =
    allInvolvementData[selectedInvolvementDataId] || unknownYourInvolvementData;

  return (
    <div id="your-involvement-modification" className="pt-4 text-left">
      <h1 className="text-gray-600">
        How were you associated with this incident?
      </h1>
      <Select
        className="mt-4"
        onChange={handleSelectChange}
        value={getSelectOptionForYourInvolvementData(currentlySelectedData)}
        options={yourInvolvementSelectOptions}
        searchable={false}
        clearable={false}
      />

      {isVehicleInvolvementType(currentlySelectedData.involvementType) && (
        <div className="my-4 w-full">
          <h2 className="text-gray-600">
            Were you in this vehicle during the incident?
          </h2>
          <div className="w-full flex flex-row">
            <label
              htmlFor="your-involvement-in-vehicle-yes"
              className="my-2 mr-4 p-2 border border-gray-300 rounded-lg w-full"
            >
              <input
                id="your-involvement-in-vehicle-yes"
                name="your-involvement-in-vehicle"
                type="radio"
                value="true"
                checked={currentlySelectedData.vehicleData?.isOccupant === true}
                onChange={onInVehicleChange}
              />
              <span className="pl-2">Yes</span>
            </label>
            <label
              htmlFor="your-involvement-in-vehicle-no"
              className="my-2 p-2 border border-gray-300 rounded-lg w-full"
            >
              <input
                id="your-involvement-in-vehicle-no"
                name="your-involvement-in-vehicle"
                type="radio"
                value="false"
                checked={
                  currentlySelectedData.vehicleData?.isOccupant === false
                }
                onChange={onInVehicleChange}
              />
              <span className="pl-2">No</span>
            </label>
          </div>
        </div>
      )}

      {isVehicleInvolvementType(currentlySelectedData.involvementType) &&
        currentlySelectedData.vehicleData?.isOccupant === false && (
          <div className="my-4 w-full">
            <h2>Are you the owner of the vehicle?</h2>
            <div className="w-full flex flex-row">
              <label
                htmlFor="your-involvement-owner-yes"
                className="my-2 mr-4 p-2 border border-gray-300 rounded-lg w-full"
              >
                <input
                  id="your-involvement-owner-yes"
                  name="your-involvement-owner"
                  type="radio"
                  value="true"
                  checked={currentlySelectedData.vehicleData?.isOwner === true}
                  onChange={onIsOwnerChange}
                />
                <span className="pl-2">Yes</span>
              </label>
              <label
                htmlFor="your-involvement-owner-no"
                className="my-2 p-2 border border-gray-300 rounded-lg w-full"
              >
                <input
                  id="your-involvement-owner-no"
                  name="your-involvement-owner"
                  type="radio"
                  value="false"
                  checked={currentlySelectedData.vehicleData?.isOwner === false}
                  onChange={onIsOwnerChange}
                />
                <span className="pl-2">No</span>
              </label>
            </div>
          </div>
        )}

      <div className="object-center p-4 w-full">
        {getGraphicElementForInvolvementData(
          currentlySelectedData,
          handleSeatmapClick,
        )}
      </div>
      <button
        type="button"
        key={JSON.stringify(primaryValue)}
        className="rounded-lg border border-gray-300 w-full py-4 px-6 mb-3 text-white text-base font-medium justify-center align-center"
        style={{ backgroundColor: '#0077B3' }}
        onClick={onConfirmClick}
      >
        <span>Confirm</span>
      </button>
    </div>
  );
};

YourInvolvementModification.stepConfig = {
  // The component provides its own submit button
  hideManualSubmit: true,
};

export default YourInvolvementModification;
