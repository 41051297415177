import type { WitnessEntryStepComponentSpec } from '@assured/step-renderer';
import { WitnessEntryButtonValue } from '@assured/step-renderer/types/step-components/WitnessEntry';
import { PartyEntryEditableList } from '../PartyEntry/PartyEntryEditableList';

type WitnessEntryListProps = {
  updateValue: (
    field: string | undefined,
    value: WitnessEntryButtonValue,
  ) => void;
  step_component: WitnessEntryStepComponentSpec;
};

export const WitnessEntryList = ({
  step_component,
  updateValue,
}: WitnessEntryListProps) => {
  const witnesses = step_component.witnesses || [];

  return (
    <PartyEntryEditableList
      parties={witnesses}
      inlineEdit={false}
      updateValue={(field, value) =>
        updateValue(field, value as WitnessEntryButtonValue)
      }
      buttons={step_component.buttons}
      field={step_component.field}
    />
  );
};
